import * as serviceWorker from "./serviceWorker";

(async function init() {
  const { bootstrap } = await import("./bootstrap");
  await bootstrap();
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
